import React from 'react';
import "./Footer.scss"
import { motion } from 'framer-motion';
import { FaLinkedin, FaGithub } from 'react-icons/fa';


let iconStyles = { color: "#031e2c"}

const Footer = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{
        opacity:
          1
      }}
      transition={{duration: 1.5}}
      className='footer'
    >
      <div className="copyright">
        <p>Copyright&copy;2023 All rights reserved. Made by <span>Ryan Rishi</span></p>
      </div>
      <div className="followMe">
        <h4>Follow Me</h4>
        <div className="stick"></div>
        <div className="social_icons" >
          <a href="https://www.linkedin.com/in/ryan-rishi" target="_blank" rel="noopener noreferrer">
              <FaLinkedin style={iconStyles}/>
          </a>

          <a href="https://www.github.com/ryan-rishi" target="_blank" rel="noopener noreferrer">
              <FaGithub style={iconStyles}/>
          </a>
        </div>
      </div>
    </motion.div>
  )
}

export default Footer