import { useState, React } from 'react';
import "./Contact.scss";
import { contacts } from '../../../Data';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';

const Contact = () => {

  let iconStyles = { color: "#031e2c", fontSize: "1.5em" };
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");

  const handleClick = async () => {
    try {
      const templateParams = {
        first_name: fName,
        last_name: lName,
        phone: phone,
        email: email,
        message: body,
        to_email: 'rrishi2024@gmail.com'
      }
      await emailjs.send('service_y0sh6vq', 'template_pjrzyb8', templateParams, '5IQR-GDmzqmUbnkwI')
      alert("Email sent!");
    } catch (error) {
      console.error(error);
      alert("Error sending email. Please try again later.");
    }
  };

  return (
    <div className="container" id="contact">
      <motion.div
        initial={{opacity: 0}}
        whileInView={{y: [-50, 0], opacity: 1}} 
        className="title"
      >
            <span>get in touch</span>
            <h1>Contact Me</h1>
      </motion.div>
      <div className="contact_form">
        <motion.div
          initial={{x: 0, opacity: 0}}
          whileInView={{ x: [-150,0], opacity: 1 }}
          transition={{duration: 1}}
          className='contact_left_container'>
          <h3>Just Say Hi!</h3>
          <p className='contact_text'>If you have any questions or want to reach out at all please feel free to send me a message. I will try to reply in a timely manner. Thank you for taking the time to learn some more about me!</p>
          {contacts.map(contact => {
            return (
              <div className='contact_left' key={contact.id}>
                <div className="icon">
                  {contact.icon}
                </div>
                <p>{ contact.infoText}</p>
              </div>
            )
          })}
          <div className="social_icons" >
                <a href="https://www.linkedin.com/in/ryan-rishi" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin style={iconStyles}/>
                </a>

                <a href="https://www.github.com/ryan-rishi" target="_blank" rel="noopener noreferrer">
                    <FaGithub style={iconStyles}/>
                </a>
              </div>
        </motion.div>
        <motion.div
          initial={{x: 0, opacity: 0}}
          whileInView={{ x: [150,0], opacity: 1 }}
          transition={{duration: 1}}
          className="contact_right"
        
        >
          <h3>Get In Touch</h3>
          <div className="row">
            <input 
              type="text" 
              placeholder='First Name' 
              value={fName}
              onChange={(e) => setFName(e.target.value)}
            />
            <input 
              type="text" 
              placeholder='Last name'
              value={lName}
              onChange={(e) => setLName(e.target.value)}
            />
          </div>
          <div className="row">
            <input 
              type="text" 
              placeholder='Phone' 
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input 
              type="email" 
              placeholder='Email' 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="row">
            <textarea 
              type="text"
              placeholder='message'
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{duration: 0.3}}
            className="btn" onClick={handleClick}
          >
            <a>Send</a>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default Contact