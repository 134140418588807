import { FaReact, FaJira, FaPhoneAlt, FaUser, FaMapMarkerAlt, FaPaperPlane, FaLinkedin, FaGithub, FaPython, FaJava, FaAws, FaDatabase } from "react-icons/fa";
import { DiJavascript } from "react-icons/di";
import { SiMysql, SiCplusplus, SiJavascript, SiSqlite, SiPostgresql } from "react-icons/si";
import { BsMedium } from "react-icons/bs";
import portfolio from "./assets/portfolio.jpg"
import work1 from "./assets/work1.png";
import work2 from "./assets/work2.png";
import work3 from "./assets/work3.png";
import work4 from "./assets/work4.png";
import work5 from "./assets/work5.png";
import work6 from "./assets/work6.png";

export const navLinks = ["home", "about", "skills", "contact"]

export const socialIcons = [
  <FaLinkedin />, <FaGithub />,
]

export const bios = [
  {
    id: 1,
    icon: <FaUser />,
    key: "Name",
    value: "Ryan Rishi"
  },
  {
    id: 2,
    icon: <FaPhoneAlt />,
    key: "Phone",
    value: "7322996948"
  },
  {
    id: 3,
    icon: <FaPaperPlane />,
    key: "Email",
    value: "rrishi2024@yahoo.com"
  }
]

export const icons = [<FaPython />, <FaJava />, <FaReact />, <FaAws />, <SiJavascript />, <FaJira />, <SiMysql/>, <SiCplusplus />]

export const experiences = [
  {
    id: 1,
    year: "Summer 2022",
    position: "Software Engineering Intern",
    company: "Silvertree",
    r1: "• Developed Automatic Error Logging Application using Python and Memfault API to catch errors in device test runs and make uploads to remote server, increasing the number of errors caught by 3x",
    r2: "• Implemented scripts in Python, YAML, and TypeScript to correlate build files between GitHub, Memfault log server, and InfluxDB database, decreasing bugs by 100% and streamlining builds by 175%.",
    r3: "• Set up and deployed InfluxDB database and Grafana dashboard on AWS EC2 instance to hold and display firmware code size information, alerting head developer if memory usage was too high."
  },
  {
    di: 2,
    year: "Summer 2021",
    position: "Software Quality Assurance Engineer",
    company: "GreatHorn", 
    r1: "• Constructed Automatic Testing Suite using Robot Framework and Selenium that simulated user experience of using website dashboard, catching 200% more release bugs.",
    r2: "• Refactored and updated 100+ Regression tests using Java and JavaScript on Jenkins, preventing 40% more versioning and revision errors.",
    r3: ""
  },
  {
    id: 3,
    year: "August 2017",
    position: "Software Engineering Intern",
    company: "READY Robotics",
    r1: "• Built AI application using Python to identify visual environmental markings, allowing robot to correctly identifysurrounding objects and distances.",
    r2: "• Affirmed quality of robotic applications through testing trials, decreasing issues with applications by 150%.",
    r3: ""
  }
]
export const finishes = [
  {
    id: 1,
    number: '<1',
    itemName: "Years Of Experience"
  },
  {
    id: 2,
    number: "10000+",
    itemName: "Lines of Code Written"
  },
  {
    id: 3,
    number: "50+",
    itemName: "Designed Items"
  },
  {
    id: 4,
    number: "15+",
    itemName: "Clients Served"
  }
]
export const workImages = [
  {
    id: 1,
    img: work1,
    name: "project 1",
    category: "web"
  },
  {
    id: 2,
    img: work2,
    name: "project 2",
    category: "web"
  },
  {
    id: 3,
    img: work3,
    name: "project 3",
    category: "web"
  },
  {
    id: 4,
    img: work4,
    name: "project 4",
    category: "app"
  },
  {
    id: 5,
    img: work5,
    name: "project 5",
    category: 'app'
  },
  {
    id: 6,
    img: work6,
    name: "project 6",
    category: "design"
  }
]


export const workNavs = [
  "All", "Professional Experience", "Projects", "Activities"
]

export const contacts = [
  {
    id: 1,
    icon: <FaMapMarkerAlt />,
    infoText: "Atlanta, GA"
  },
  {
    id: 2,
    icon: <FaPaperPlane />,
    infoText: "rrishi2024@yahoo.com"
  },
  {
    id: 3,
    icon: <FaPhoneAlt />,
    infoText: "732-299-6948"
  }
]
